import React from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom' // Import Link from React Router

import { useTheme } from '@mui/material/styles'
import LINKS from 'utils/constants/links'

interface BannerCardProps {
  title: string // Bolded content
  subTitle: string // Main content
  optionalInfo?: string // Help text
  imageSrc?: string // Background image URL
  buttonLabel?: string
  buttonColor?: string
  backgroundColor?: string
  body?: string
  daysLeftInRrpTrial: number
  buttonLink: string | '/'
}

interface TransformedContentProps {
  content: string
  daysLeftInRrpTrial?: number
}

const TransformedContent: React.FC<TransformedContentProps> = ({ content, daysLeftInRrpTrial }) => {
  const linkMap: Record<string, string | number | undefined> = {
    '{AMOUNT_OF_DAYS_LEFT}': daysLeftInRrpTrial,
    'Learn more': '/store/rrpFreeTrial',
    'Unyte Academy': '/academy',
    'share your RRP experience': LINKS.support.rrpfeedback,
    'reach out with any questions': 'https://connect.unyte.com/meetings/unyte/success-mu',
  }

  const renderContent = (text: string) => {
    const pattern = new RegExp(`(${Object.keys(linkMap).join('|')})`, 'g')
    const parts = text.split(pattern)

    return parts.map((part, index) => {
      const key = Object.keys(linkMap).find(
        (keyword) => keyword.toLowerCase() === part.toLowerCase()
      )

      if (key) {
        const value = linkMap[key]
        if (typeof value === 'number') {
          // Handle numeric replacements (e.g., {AMOUNT_OF_DAYS_LEFT})
          return (
            <Box
              component="span"
              key={`amount-of-days-${index}`}
              sx={{
                fontSize: '1.33rem',
                fontWeight: 600,
              }}
            >
              {value}
            </Box>
          )
        } else if (typeof value === 'string') {
          if (value.startsWith('/')) {
            // Internal link
            return (
              <Link style={{ color: 'inherit' }} to={value} key={`link-${index}`}>
                {key}
              </Link>
            )
          } else {
            // External link
            return (
              <a
                style={{ color: 'inherit' }}
                href={value}
                key={`external-link-${index}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {key}
              </a>
            )
          }
        }
      }

      // Plain text
      return <span key={`text-${index}`}>{part}</span>
    })
  }

  return <>{renderContent(content)}</>
}

export const DashboardBanner: React.FC<BannerCardProps> = ({
  title,
  subTitle,
  optionalInfo,
  imageSrc,
  buttonLabel,
  buttonColor,
  backgroundColor,
  body,
  daysLeftInRrpTrial,
  buttonLink,
}) => {

  const theme = useTheme()
  const colors = theme.palette

  const fontColor = backgroundColor === String(colors.primary.main) ? 'white' : colors.primary.main

  // if we don't have a title then we don't have a card
  if (!title) {
    return null
  }

  return (
    <Stack direction="row" sx={{ position: 'relative', width: '100%' }}>
      {/* Left colored background box */}
      <Box
        sx={{
          minWidth: '69%',
          minHeight: { md: '100px', sm: '150px', xs: '200px' },
          borderTopRightRadius: '100px',
          borderBottomRightRadius: '100px',
          zIndex: '1',
          backgroundColor,
        }}
      >
        <Stack px={5} py={2} spacing={1} sx={{ maxWidth: '1100px', marginRight: 'auto' }}>
          <Typography
            variant="body1"
            component="div"
            color="primary"
            sx={{
              fontSize: '1.2rem',
              span: {
                fontWeight: 600,
              },
              color: fontColor,
            }}
          >
            <span>
              <TransformedContent content={title} daysLeftInRrpTrial={daysLeftInRrpTrial} />
            </span>{' '}
            {subTitle && (
              <TransformedContent content={subTitle} daysLeftInRrpTrial={daysLeftInRrpTrial} />
            )}
          </Typography>

          {body && (
            <Typography
              variant="body1"
              component="div"
              color="primary"
              sx={{
                fontSize: '0.875rem',
                color: fontColor,
              }}
            >
              <TransformedContent content={body} daysLeftInRrpTrial={daysLeftInRrpTrial} />
            </Typography>
          )}

          {/* Optional Help Text */}
          {optionalInfo && (
            <Typography variant="body2" sx={{ marginTop: 1, color: fontColor }} fontStyle="italic">
              {optionalInfo}
            </Typography>
          )}
          {buttonLabel && (
            <Box sx={{ margin: 'auto', display: { sm: 'block', md: 'none' } }}>
              <Link to={buttonLink}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: buttonColor,
                    '&:hover': {
                      backgroundColor: buttonColor,
                    },
                  }}
                >
                  <Typography noWrap color="white">
                    {buttonLabel}
                  </Typography>
                </Button>
              </Link>
            </Box>
          )}
        </Stack>
      </Box>

      {/* Right background image box */}
      <Box
        sx={{
          display: { xs: 'none', sm: 'none', md: 'flex' },
          width: '100%',
          minHeight: { md: '100px', sm: '150px', xs: '200px' },
          marginLeft: '-100px',
          backgroundImage: imageSrc ? `url(${imageSrc})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: '0',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {buttonLabel && (
          <Box sx={{ marginLeft: 'calc(100px + 4rem)' }}>
            <Link to={buttonLink}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: buttonColor,
                  '&:hover': {
                    backgroundColor: buttonColor,
                  },
                }}
              >
                <Typography noWrap color="white">
                  {buttonLabel}
                </Typography>
              </Button>
            </Link>
          </Box>
        )}
      </Box>
    </Stack>
  )
}
