import React, { useEffect, useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { useNavigate } from 'react-router'
import {
  Typography,
  Box,
  TextField,
  Button,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'components'
import { gql, useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { DashboardBanner as Banner } from 'views/dashboard/components/banner'
import { themeDefinition } from 'utils/theme/Theme'
import MarkunreadIcon from '@mui/icons-material/Markunread'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import LockIcon from '@mui/icons-material/Lock'

const colors = themeDefinition.palette

const CREATE_REFERRAL = gql`
  mutation createReferral($referral: CreateReferralInput!) {
    createReferral(referral: $referral) {
      firstName
      lastName
      email
    }
  }
`

const CustomOrderedList = ({ index, text }) => (
  <Stack direction="row" pb={2}>
    <Box
      sx={{
        padding: 1,
        color: colors.pink.main,
        fontSize: '36px',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
      }}
    >
      {index}
    </Box>
    <Typography alignSelf="center" pl={1}>
      {text}
    </Typography>
  </Stack>
)

const ReferralSubmittedPage = ({ onSubmit }) => (
  <Stack direction="column" pt={5} px={3} width="60%">
    <Typography pb={2} pl={2} variant="h5" color="pink">
      Thanks for Sharing the Gift of Nervous System Health!
    </Typography>
    <Typography pl={2} color="primary">
      Here’s what happens next:
    </Typography>
    <List>
      <ListItem>
        <ListItemIcon>
          <MarkunreadIcon />
        </ListItemIcon>
        <ListItemText
          primary="We’ll Reach Out to Your Referral"
          secondary="Your friend or colleague will get an email invitation to explore how Unyte’s tools can enhance their practice and their clients’ lives. We’ll let them know you referred them, and if they subscribe, they’ll enjoy a free month thanks to you!"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <EmojiEventsIcon />
        </ListItemIcon>
        <ListItemText
          primary="You’ll Be Notified"
          secondary="When your referral subscribes to an annual plan, you’ll both get a free month as our thank-you!"
        />
      </ListItem>
    </List>
    <Button
      onClick={() => onSubmit(false)}
      aria-label="Refer Another Friend"
      variant="contained"
      color="pink"
      sx={{
        ml: 2,
        alignSelf: 'flex-start',
      }}
    >
      <Typography color="white">Refer Another Friend</Typography>
    </Button>
  </Stack>
)

const ReferralForm = ({ control, handleSubmit, onSubmit, loading, formFields }) => (
  <Box
    component="form"
    onSubmit={handleSubmit(onSubmit)}
    p={3}
    sx={{
      maxWidth: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      gap: 2,
      margin: 'auto',
      backgroundColor: colors.mint.main,
      borderRadius: 1,
    }}
  >
    {formFields.map(({ key, label, rules }) => (
      <Controller
        key={key}
        name={key}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={label}
            type={key}
            helperText={error && error.message}
            error={!!error}
          />
        )}
      />
    ))}
    <Stack direction="row" alignItems="center" spacing={1} color={colors.gray.medium}>
      <LockIcon />
      <Typography variant="caption">
        <i>
          We respect your privacy. Your friend’s information will only be used for this referral, no
          spam, no sharing.
        </i>
      </Typography>
    </Stack>
    <Button type="submit" variant="contained" color="pink" disabled={loading}>
      <Typography color="white">{loading ? 'Submitting...' : 'Get My Free Month!'}</Typography>
    </Button>
  </Box>
)

export default function ReferralProgram() {
  const navigate = useNavigate()

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [createReferral, { loading }] = useMutation(CREATE_REFERRAL)
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { firstName: '', lastName: '', email: '' },
    mode: 'onChange',
    criteriaMode: 'all',
  })

  const formFields = [
    {
      key: 'firstName',
      label: 'First Name',
      rules: {
        required: 'First name is required',
        maxLength: { value: 100, message: 'First name must be no longer than 100 characters' },
      },
    },
    {
      key: 'lastName',
      label: 'Last Name',
      rules: {
        required: 'Last name is required',
        maxLength: { value: 100, message: 'Last name must be no longer than 100 characters' },
      },
    },
    {
      key: 'email',
      label: 'Email',
      rules: {
        required: 'Email is required',
        pattern: { value: /^\S+@\S+\.\S+$/, message: 'Email format is invalid' },
      },
    },
  ]

  const onSubmit = async (formData) => {
    try {
      await createReferral({ variables: { referral: formData } })
      reset()
      setIsSubmitted(true)
    } catch (err) {
      console.error('Submission Error:', err.message)
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === 'prod') navigate('/')
    // eslint-disable-next-line
  }, [])

  return (
    <MainLayout title="Unyte Provider Referral Program">
      <Banner
        title="Share the Power of the Nervous System Health"
        optionalInfo="Invite friends or colleagues to discover the Nervous System Health, earn free months, and enjoy peace of mind. Your referrals stay private and secure."
        backgroundColor={colors.mint.main}
        imageSrc="/images/referral/referral-banner.png"
      />
      {isSubmitted ? (
        <ReferralSubmittedPage onSubmit={setIsSubmitted} />
      ) : (
        <Stack direction="row">
          <Box width="50%" p={5}>
            <Typography sx={{ color: colors.pink.main, fontSize: '2.5rem', pb: 3 }}>
              Give a Month, Get a Month!
            </Typography>
            <CustomOrderedList
              index={1}
              text="Submit your friend or colleague’s name and email. We’ll send them an email, introducing them to Unyte and explaining the process."
            />
            <CustomOrderedList
              index={2}
              text="If your referral becomes a Unyte Provider with an active annual subscription, you’ll both receive a one-month credit!"
            />
            <Typography py={3} sx={{ color: colors.pink.main, fontSize: '1.2rem' }}>
              Unlimited referrals, endless free months, lasting impact.
            </Typography>
            <Typography variant="body2">
              *Terms, conditions and exclusions apply. Referrals must be{' '}
              <span style={{ color: colors.pink.main }}>first-time Unyte subscribers</span>. Rewards
              are eligible for new{' '}
              <span style={{ color: colors.pink.main }}>annual subscription plans</span> only. A new
              subscriber can only receive{' '}
              <span style={{ color: colors.pink.main }}>one referral credit</span>.
            </Typography>
          </Box>
          <ReferralForm
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            loading={loading}
            formFields={formFields}
          />
        </Stack>
      )}
    </MainLayout>
  )
}
