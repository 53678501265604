import React, { useState } from 'react'
import {
  Box,
  Button,
  Container,
  Card,
  CardMedia,
  Grid,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'

import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { RRP_BANNER_STATE_4_PURCHASED } from 'utils/constants/rrp-banner-cards'
import LINKS from 'utils/constants/links'

export function ErrorStartingFreeTrialMsg({ palette }) {
  return (
    <>
      Sorry, we had an issue starting your free RRP Trial. Please{' '}
      <Link
        underline="hover"
        style={{ cursor: 'pointer', color: `${palette.pink.main}` }}
        onClick={() => {
          window.location.reload()
        }}
      >
        {' '}
        try again
      </Link>{' '}
      or contact support if this issue persists.
    </>
  )
}

export default function RRPFreeSampleStoreFront({
  rrpTrialState,
  daysLeftInTrial,
  openInNewTab,
  redirctTo,
  startFreeSample,
  startFreeSampleError,
  theme,
}) {
  const { palette } = theme

  const getDynamicContent = ({ daysLeftInTrial }) => ({
    RRP_BANNER_STATE_1_OFFER: {
      banner: '',
      button: 'Start My 30-Day Trial',
      action: () => {
        startFreeSample()
      },
    },
    RRP_BANNER_STATE_2_ACCEPTED: {
      banner: (
        <span>
          Thank you for opting in! Please visit the{' '}
          <Link
            style={{ cursor: 'pointer', color: `${palette.pink.main}` }}
            onClick={() => redirctTo('/academy')}
          >
            Unyte Academy
          </Link>{' '}
          to complete your RRP preliminary training. Once training is complete you will gain access
          to RRP.
        </span>
      ),
      button: 'Complete Training',
      action: () => {
        redirctTo('/academy')
      },
    },
    RRP_BANNER_STATE_3_ACTIVE: {
      banner: (
        <span>
          We hope you are enjoying your 30-day RRP trial! Please{' '}
          <Link
            style={{ cursor: 'pointer', color: `${palette.pink.main}` }}
            href={LINKS.support.rrpfeedback}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {'share feedback'}
          </Link>{' '}
          on your experience. You can{' '}
          <Link
            style={{ cursor: 'pointer', color: `${palette.pink.main}` }}
            onClick={() => redirctTo('/store/rrp')}
          >
            {'purchase your subscription'}
          </Link>{' '}
          any time.
        </span>
      ),
      button: 'RRP Purchase Options',
      action: () => {
        redirctTo('/store/rrp')
      },
    },
    RRP_BANNER_STATE_4_PURCHASED: {
      banner: (
        <span>
          Congratulations on your Rest and Restore Protocol™ subscription purchase!
          {daysLeftInTrial ? (
            <>
              {' '}
              Your RRP subscription will start in{' '}
              <span
                style={{ color: `${palette.pink.main}`, fontWeight: 600, fontSize: 18 }}
              >{`${daysLeftInTrial}`}</span>{' '}
              days when your free trial ends.
            </>
          ) : (
            'Your RRP subscription will start when your free trial ends.'
          )}
        </span>
      ),
      button: 'Share Your Feedback',
      action: () => {
        openInNewTab(` ${LINKS.support.rrpfeedback} `)
      },
    },
    RRP_BANNER_STATE_6_MULTIPROVIDER: {
      banner: (
        <span>
          Your organization is currently enjoying a free RRP trial. Join the trial below to access
          your short RRP preliminary training. Once training is complete you will gain access to
          RRP.
        </span>
      ),
      button: 'Join Free Trial',
      action: () => {
        startFreeSample()
      },
    },
  })

  const dynamicContent = getDynamicContent({ daysLeftInTrial })

  const [loading, setLoading] = useState(false)
  const handleButtonClick = async () => {
    if (loading) return
    try {
      setLoading(true)
      await dynamicContent[rrpTrialState].action()
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      {dynamicContent[rrpTrialState] && dynamicContent[rrpTrialState].banner && (
        <Container maxWidth="md" sx={{ margin: '0 0 10px 0' }}>
          <Card
            elevation={0}
            sx={{ backgroundColor: `${palette.yellow.light}`, padding: '30px', fontWeight: 700 }}
          >
            <Grid container>
              <Grid item sm={1}>
                {rrpTrialState === RRP_BANNER_STATE_4_PURCHASED ? (
                  <CheckCircleIcon color="primary" fontSize="large" />
                ) : (
                  <InfoTwoToneIcon color={'primary'} fontSize={'large'} />
                )}
              </Grid>
              <Grid item sm={11}>
                <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                  {dynamicContent[rrpTrialState].banner}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Container>
      )}
      <Container maxWidth="md" sx={{ margin: '0 0 50px 0' }}>
        <Card elevation={0}>
          <Grid
            container
            alignItems="stretch"
            bgcolor={`${palette.mint.main}`}
            justifyItems="space-between"
            p={3}
          >
            <Grid item sm={7} pl={1} pr={4}>
              <Typography
                color={`${palette.primary.main}`}
                sx={{ fontSize: 25, fontWeight: 500 }}
                mb={3}
              >
                Explore Rest and Restore Protocol™ with a complimentary 30-day trial!
              </Typography>
              <Typography sx={{ fontWeight: 400 }}>
                This complimentary trial is designed to help you experience RRP's potential to
                support deep relaxation, recovery, balance, and connection to self — helping your
                clients cultivate interoception and self-regulation.
              </Typography>
              <br />
              <Typography sx={{ fontWeight: 600 }}>
                This complimentary trial is 100% free and there is no purchase obligation at trial
                end.
              </Typography>
              <br />
              <Typography
                color={`${palette.primary.main}`}
                sx={{ fontSize: 20, fontWeight: 500 }}
                mb={2}
              >
                How Your Trial Works:
              </Typography>
              <Container pl={3} sx={{ fontSize: 15 }}>
                <List sx={{ listStyleType: 'disc' }}>
                  <ListItem
                    disablePadding
                    sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '5px' }}
                  >
                    <span style={{ fontWeight: 700, margin: '0 6px 0 0' }}>
                      Start 30-Day Trial When Ready:
                    </span>
                    Your trial begins only after you opt in below.
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '5px' }}
                  >
                    <span style={{ fontWeight: 700, margin: '0 6px 0 0' }}>
                      Complete Short Preliminary Training:
                    </span>
                    To access RRP, you must complete the preliminary training, after which you’ll
                    receive 5 free client licenses to use during your trial.
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '5px' }}
                  >
                    <span style={{ fontWeight: 700, margin: '0 6px 0 0' }}>
                      No Risk, No Auto-Enroll:
                    </span>
                    Purchase an RRP subscription anytime during or after the trial through the Unyte
                    Store.
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '5px' }}
                  >
                    <span style={{ fontWeight: 700, margin: '0 6px 0 0' }}>
                      Discount for SSP subscribers!
                    </span>
                    Add an RRP subscription for as little as $60/month — over 50% discount!
                  </ListItem>
                </List>
              </Container>
              <br />
              <Typography sx={{ fontSize: 15, fontWeight: 600 }} mb={2}>
                Thank you for joining us on this journey with Rest and Restore Protocol™ (RRP)!
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 400, fontStyle: 'italic' }} mb={2}>
                Complimentary trial offer available through March 31, 2025.
              </Typography>
            </Grid>
            <Grid item sm={5} p={3} sx={{ bgcolor: 'white' }}>
              <Stack spacing={2}>
                <div>
                  <CardMedia
                    image="/images/store/rrp-free-sample.png"
                    sx={{
                      height: '150px',
                      width: '100%',
                      borderRadius: '5px',
                    }}
                  >
                    <CardMedia
                      image="/images/store/sonocea-enhanced-linear-dark.png"
                      sx={{
                        width: '100px',
                        height: '30px',
                        borderRadius: '5px',
                        position: 'relative',
                        top: '112px',
                        right: '-8px',
                      }}
                    />
                  </CardMedia>
                </div>
                <Typography
                  color={`${palette.primary.main}`}
                  sx={{ fontSize: 18, fontWeight: 500 }}
                >
                  We're delighted to accompany you as you explore RRP!
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: 15 }}>
                  Developed by Dr. Stephen Porges and Anthony Gorry, Rest and Restore Protocol™
                  (RRP) is an innovative, clinical-grade listening therapy designed to help calm and
                  restore mental and physical functioning.
                </Typography>
                <Typography
                  color={`${palette.primary.main}`}
                  sx={{ fontSize: 16, fontWeight: 500 }}
                >
                  RRP has been reported to help clients:
                </Typography>
                <Container disableGutters sx={{ fontSize: 15 }}>
                  <List sx={{ listStyleType: 'disc', paddingLeft: '18px' }}>
                    <ListItem
                      disablePadding
                      sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '3px' }}
                    >
                      Become more accessible to therapy
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '3px' }}
                    >
                      Enhance autonomic regulation
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '3px' }}
                    >
                      Improve gut function
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '3px' }}
                    >
                      Feel more relaxed, calm, and at ease
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '3px' }}
                    >
                      Sleep better
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '3px' }}
                    >
                      Feel less anxious and depressed
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{ display: 'list-item', fontWeight: 300, paddingBottom: '3px' }}
                    >
                      Have greater access to their feelings
                    </ListItem>
                  </List>
                </Container>
                <Box textAlign="center">
                  {startFreeSampleError || !dynamicContent[rrpTrialState] ? (
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        fontStyle: 'italic',
                        textAlign: 'center',
                      }}
                    >
                      {startFreeSampleError}
                    </Typography>
                  ) : (
                    <Button
                      color="pink"
                      variant="contained"
                      onClick={handleButtonClick}
                      data-test="start-rrp-free-sample-button"
                    >
                      {dynamicContent[rrpTrialState].button}
                    </Button>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item sm={12} mt={6}>
              <Typography
                mx={5}
                sx={{ fontSize: 12, fontWeight: 400, fontStyle: 'italic', textAlign: 'center' }}
              >
                Statements on the RRP have not been evaluated by the FDA, and Unyte products and
                services are not intended to diagnose, treat, cure, or prevent any disease. Unyte
                products are not medical devices or medical instruments.
              </Typography>
              <br />
              <Typography
                mx={5}
                sx={{ fontSize: 12, fontWeight: 400, fontStyle: 'ialic', textAlign: 'center' }}
              >
                It is solely the responsibility of each user (whether a professional user or
                personal user) to determine whether the products and/or services may be beneficial
                for their patients/clients or themselves.
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  )
}
